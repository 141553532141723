<template>
  <div>
    <div v-if="!menuOpen" class="md:hidden px-3 text-2xl"><i class="fas fa-bars py-3" @click.prevent="openNavigation"></i></div>

    <!-- Desktop Menu -->
    <nav ref="desktop_navigation"  class="hidden md:block bg-white mt-6">
      <slot></slot>
    </nav>


    <!-- Mobile Menu -->
    <nav ref="mobile_navigation" v-if="menuOpen"  class="absolute bg-gray inset-0 z-30">
      <i class="fas fa-times px-3 py-3 text-2xl" @click.prevent="closeNavigation"></i>
      <div class="text-center px-6 pt-8 text-2xl">
        <slot></slot>
      </div>
    </nav>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default {
    data() {
      return {
        menuOpen: false,
      }
    },
    methods: {
      openNavigation() {
        this.menuOpen = true;
      },
      closeNavigation() {
        this.menuOpen = false;
      }
    }
  }
</script>
