var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.menuOpen
      ? _c("div", { staticClass: "md:hidden px-3 text-2xl" }, [
          _c("i", {
            staticClass: "fas fa-bars py-3",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.openNavigation($event)
              }
            }
          })
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "nav",
      {
        ref: "desktop_navigation",
        staticClass: "hidden md:block bg-white mt-6"
      },
      [_vm._t("default")],
      2
    ),
    _vm._v(" "),
    _vm.menuOpen
      ? _c(
          "nav",
          {
            ref: "mobile_navigation",
            staticClass: "absolute bg-gray inset-0 z-30"
          },
          [
            _c("i", {
              staticClass: "fas fa-times px-3 py-3 text-2xl",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.closeNavigation($event)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-center px-6 pt-8 text-2xl" },
              [_vm._t("default")],
              2
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }